.Logout {
    
    user-select: none;

    & input[type="text"],
    & input[type="password"],
    & input[type="tel"],
    & input[type="number"],
    & input[type="email"],
    & textarea {
        padding: 16px 30px 16px 45px;
    }
    
    & .icon {
        @include border-radius(60px);
        color:$white;
        background-color:$green;
        width:60px;
        height:60px;
        padding:15px;
        font-size:16px;
    }
}