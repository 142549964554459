$color_1: $white;
$border-color_1: $white transparent transparent transparent;

/* custom select boxes */
/* custom select */
/* custom toggle switch */
/* default select */
	select {
		width: 100%;
		height: 100%;
		background: none;
		border: none;
		padding: 0 50px 0 20px;
		cursor: pointer;
		font: 500 14px/1.5 'Montserrat', sans-serif;
		text-indent: 5px;
		&::-ms-expand {
			display: none;
		}
		&:focus {
			outline: none;
		}
	}
	.select {
		position: relative;
		background: $white;
		border: 1px solid $green;
    border-radius: 8px;
    overflow: hidden;
		cursor: pointer;
		&:before {
			content: '';
			background: $green;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 40px;
			pointer-events: none;
		}
		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0;
			border-color: $border-color_1;
			position: absolute;
			right: 11px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

  /*
  Custom Select
  -------------
  */
.customSelect {
	select {
		width: 100%;
		height: 100%;
		background: none;
		border: none;
		-webkit-appearance: none;
		padding: 0 50px 0 20px;
		cursor: pointer;
		text-indent: 5px;
		&::-ms-expand {
			display: none;
		}
		&:focus {
			outline: none;
		}
	}
	.select {
		position: relative;
		background: $white;
		border: 1px solid $green;
		cursor: pointer;
		&:before {
			content: '';
			background: $green;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 40px;
			pointer-events: none;
		}
		&:after {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 10px 7px 0;
			border-color: $border-color_1;
			position: absolute;
			right: 11px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
/*
Custom toggle button
--------------------
*/
.customToggle {
	input[type="checkbox"] {
		position: absolute;
		left: -9999px;
		&:checked {
			+ {
				label {
					background: $green;
					&:before {
						transform: translateX(38px);
					}
					.yes {
						display: inline-block;
					}
					.no {
						display: none;
					}
				}
			}
		}
	}
	label {
		border: 1px solid transparent;
		background: $green;
		border-radius: 25px;
		position: relative;
		width: 60px;
		height: 25px;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		transition: background 0.3s linear;
		cursor: pointer;
		&:before {
			content: '';
			background: $white;
			height: 28px;
			width: 33px;
			position: absolute;
			top: 3px;
			left: 3px;
			border-radius: 25px;
			transform: translateX(0);
			transition: transform 0.2s cubic-bezier(0.1, 0.8, 0.32, 1.2), background 0.2s linear;
		}
		span {
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%);
			color: $color_1;
		}
		.yes {
			display: none;
		}
		.no {
			left: auto;
			right: 10px;
			display: inline-block;
		}
	}
}
