html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


body {
  color: $base-text-color;
  background: $base-background-color;
  font: #{$base-font-size}/#{$base-line-height} $base-font-family;
  min-width: $base-min-width;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.gm-style img {
  max-width: none
}

@media only screen and (min-width:1025px) {

  a[href^=tel],
  a[href^=skype],
  a[href^=sms] {
    cursor: default;
    pointer-events: none;
  }
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}