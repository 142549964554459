body {
// Input fields
& input[type="text"],
& input[type="password"],
& input[type="tel"],
& input[type="number"],
& input[type="email"],
& select,
& textarea {
    @include border-radius (7px);
    background:$white;
    padding: 16px 30px 16px 16px;
    width:100%;
    border:none;
    font-size: 18px;
    font-family: $primary-font;

    &:hover {
        background-color:$paleblue;
        @include transition(0.3s ease-in all);
    }
 }

 & fieldset {
    border-style:solid;
    border-color: $green;
    margin: 20px 0;
    padding: 10px 20px;
 }

 & legend {
    font-weight: bold;
    color: $blue;
    margin: 20px 0;
    padding: 0 20px;
    font-size: 25px;
 }

 & label {
    @include inline-block;
    margin:0 0 5px;
 }
 textarea {
    padding:15px;
    resize: none;
 }

 & .form-group,
 & .input-group,
 & .input-group-btn {
    margin: 10px 0;
}

main {

& .formContainer {
    background: rgba($paleblue, 0.9);
    padding: 60px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    @include inline-block;
    width: 100%;
    margin-top:120px; 
}

& .formContainer.status {
    margin-top:20px!important;
}

& .row {
    position: relative;
    margin:15px 0 0;

    & .seperator {
        padding:1px;
        &:before {
            content: '';
            width: 40%;
            height: 1px;
            background: $black;
            display: block;
            position: absolute;
            top: 12px;
            left:0;
        
        }
        &:after {
            content: '';
            width: 40%;
            height: 1px;
            background: $black;
            display: block;
            position: absolute;
            top: 12px;
            right:0;
        
        }
    }
    
    & .icon {
        position: absolute;
        top:17px;
        left:15px;
        color:$blue;
        font-size:20px;
    }
}
            & .buttonsContainer {
                max-width: 520px;
                margin-left:auto;
                margin-right:auto;
            }

            & .buttonsContainerfullwidth {
                max-width: 100%;
                margin-left:auto;
                margin-right:auto;
            }

            & .form {
                margin-left: auto;
                margin-right: auto;
                max-width: 900px;

            }
    }
}