// Megamenu 
.megamenuToggle {
    cursor: pointer;
    color:$white;
    position: absolute;
    right:0;
    padding:12px 20px;
    background-color: transparent;

    & h3 {
        color: $white;
        margin: 0;
        border: none;
    }

    & a {
        color: $white;
    }
}

.Megamenu {
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 320px;
    width: 100%;
    background: $blue;
    color: $white;
    height:100%;
    z-index:9999;
    overflow: hidden;
    transition: max-width 0.3s ease; /* Adjust the duration and easing as needed */

    & a {
        color: $white;
    }
 
    & h3 {
        display: block;
        padding:10px 20px 0;
        color:$white;
        margin:10px 0;
    }
    & a {
        display: block;
        padding: 10px 20px;
        border-bottom: 1px solid #121951;
    }

    & .icon {
        cursor: pointer;
        margin: 0 10px 0 0;
    }
}

.Megamenu.hidden {
    max-width: 0;
}

// Overlay
.overlay, .overlayLogout { 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, 0.6);
    z-index: 10;
    visibility: hidden;
}

.overlay.hidden {
    visibility: hidden;
}

.overlay.visible {
    visibility: visible;
}