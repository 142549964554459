// Margins & Paddings

.nomargin {
    margin:0!important;
}

.nopadding {
    padding:0!important;
}

.nomarginleft {
    margin-left:0!important;
}

.nopaddingleft {
    padding-left:0!important;
}

.nomarginright {
    margin-right:0!important;
}

.nopaddingright {
    padding-right:0!important;
}