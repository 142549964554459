 // Header (Nav)

 .nav {
    @include flexbox;
    @include boxsizing;
    background-color:$green;
    color:$white;
    align-items: flex-start;
    justify-content: inherit;
    padding:10px 25px;
    flex-grow: 1;
    background-image:url(../../assets/images/EWRStickyHeader.PNG);
    background-repeat: no-repeat;
    background-position: top right;
    max-height: 60px;

    & .logo {
        margin:0 0 0 0;
        flex-grow:2;
    }

    & .menuItem {
        @include flexbox;
    }

    & ul {
        list-style-type: none;
        justify-content: flex-end;
        flex-grow: 2;
        text-align: right;

        & li {
            list-style-type:none;
            display: inline-block;

            & a:hover {
                color:$white;
                text-decoration: underline;
            }
        }
    }

    & .logo img {
        max-width: 190px;
        margin: 0;
    }
    & .logout {
        color: $white;
        flex-grow: 2;
        text-align: right;
    }

    & .logoutlink {
        @include flexbox;
        flex-direction: column;
        background-color:$red;
        color:$white;
        @include boxsizing;
        @include border-radius(7px); 
        align-items: center;
        padding:5px 15px;
        margin-right:0;
        flex-direction: row;
        margin-left:10px;
        margin-right:40px;

        & * {
            color:$white;
        }

        &:hover {
            @include transition(3s);
            background-color:$tomato;
        }
    }

    & .statuslink {
        @include flexbox;
        flex-direction: column;
        background-color:$green;
        color:$white;
        @include boxsizing;
        @include border-radius(7px); 
        align-items: center;
        padding:5px 15px;
        margin-right:0;
        flex-direction: row;

        & * {
            color:$white;
        }

        &:hover {
            @include transition(3s);
            background-color:$lightblue;
        }
    }
 }