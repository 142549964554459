html,body {
	width:100%;
	height:100%;
}

body {
	background:url(../../assets/images/EWRFooter-v4.png) bottom left no-repeat, 
	url(../../assets/images/Ewr-t.jpg) bottom left no-repeat;
	background-size:100%;
	background-attachment: fixed;
	overflow-x: hidden;
	height:100%;
	position: relative;
	font-size: $base-font-size;
	line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font-family;
  margin: 0 0 25px;
  color: $headings-color;
  color:$blue;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

@media only screen and (max-width:767px) {
	h1 {
	  font-size: $h1-mobile-font-size;
	}

	h2 {
	  font-size: $h2-mobile-font-size;
	}

	h3 {
	  font-size: $h3-mobile-font-size;
	}

	h4 {
	  font-size: $h4-mobile-font-size;
	}

	h5 {
	  font-size: $h5-mobile-font-size;
	}

	h6 {
	  font-size: $h6-mobile-font-size;
	}
}

p {
  margin: 0 0 25px;
  white-space: normal;
}

a {
  color: $blue;
  text-decoration: none;
  transition: color 0.3s linear;
  &:hover,
  &:focus {
    text-decoration:none;
    /* color: darken($default-link-color, 15%); */
	color:$lightblue;
  }
}

input,select,button,textarea {
	outline:none;
}

ul, ol, li {
	margin:0;
	padding:0;
}
ul, ol {
	margin:25px 0;
}
ul li {
	list-style:disc;
}
ol li {
	list-style:decimal;
}

ul, li, ol {
	line-height:$base-line-height;
	padding-left:20px;
    &.unstyled {
        list-style-type:none;
    }
}

dl {
    border: 3px double #ccc;
    padding: 0.5em;
}
dt {
	float: left;
	clear: left;
	width: 100px;
	text-align: right;
	font-weight: bold;
	color: $red;
}
dt::after {
	content: ":";
}
dd {
	margin: 0 0 0 110px;
	padding: 0 0 0.5em 0;
}

input,button {
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-family: $base-font-sans-serif;
}