$color_1: $green;
$font-family_1: 'Font Awesome 5 Free';
$border-color_1: transparent transparent $green $green;
$border-color_2: transparent transparent #fff #fff;

/* CUSTOM CHECKBOXES */
/* custom radio buttons */
/* custom checkboxes */
/*
	Add this to make your checkboxes coloured :)
*/
 
.formContainer {
	input[type="checkbox"] {
		position: absolute;
		left: -9999px;
		+ {
			label {
				position: relative;
				cursor: pointer;
				padding: 0px 0 0 45px;
				display: inline-block;
				vertical-align: middle;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					border: 2px solid $green;
					width: 25px;
					height: 25px;
					background: #fff;
					display: inline-block;
					vertical-align: middle;
					border-radius: 3px;
				}
				&:after {
					content: '';
					border-style: solid;
					border-width: 0 0 2px 2px;
					border-color: $border-color_1;
					width: 15px;
					height: 10px;
					position: absolute;
					top: 5px;
					left: 5px;
					opacity: 0;
					transform: scale(2) rotate(-45deg);
					transition: transform 0.3s linear, opacity 0.3s linear, background-color 0.3s ease;
				}
			}
		}
		&:checked+label {
			&:after {
				opacity: 1;
				transform: scale(1) rotate(-45deg);
				border-color: $border-color_2;
			}
		}
		&:checked {
			+ {
				label {
					&:before {
						background: $green;
					}
				}
			}
		}
	}
}
.holder {
	border-bottom: 1px dashed #ccc;
	margin: 0 0 20px;
	padding: 0 0 30px;
}
