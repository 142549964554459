.Login 
     {
         
        user-select: none;

        & input[type="text"],
        & input[type="password"],
        & input[type="tel"],
        & input[type="number"],
        & input[type="email"],
        & textarea {
            padding: 16px 30px 16px 45px;
        }
        & label {
            display: none;
        }

            & .LoginImage {
                background-color: $blue;
                color:$white;
                @include inline-block;
                @include border-radius(50px);
                padding:5px;
                margin:15px;
                width:65px;
                height:65px;
            }

            & .spinner {
                max-width:50px;
                margin: 15px auto;
            }
        }
